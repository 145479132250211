<template>
  <main class="auth" id="content">
    <router-view />

    <div class="btn-wrap">
        <a href="#" class="btn_underline"
            v-html="$t('content.auth.Tab.SignIn')"
            v-if="currentTab===1"
            @click.prevent="onTabChange(0)"
            >
        </a>
        <a href="#" class="btn_underline"
            v-html="$t('content.auth.Tab.SignUp')"
            v-else
            @click.prevent="onTabChange(1)"
            >
        </a>
    </div>
  </main>
</template>

<script>
import Auth from '@/views/auth/Auth.vue';

export default {
  name: 'MobileAuth',
  extends: Auth,
};
</script>
